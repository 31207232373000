<template>
  <div class="my-page">
    <div class="page-user-noticeview">
      <div class="my-page">
        <h2>{{ viewData.Title }}</h2>
        <div class="notice-des">
          <span>{{ viewData.RealName }}发布</span>
          <span class="time">
            <van-icon name="clock-o" />
            {{ viewData.CreateDate }}
          </span>
        </div>
        <div class="notice-content" v-html="viewData.Content"></div>
      </div>
    </div>
    <div class="notice-file">
      <span>附件下载</span>
      <div class="" v-for="(item, i) in viewData.accessoryList" :key="i">
        <p @click="fileReview(item)" style="display:inline-block">{{ item.name }}</p>
        <a @click="review(item)">下载</a>
      </div>
    </div>
    <div class="notice-resources">
      <span>相关信息</span>
      <div
        class=""
        v-for="(item, i) in viewData.resources"
        :key="i"
        @click="viewResources(item)"
      >
        {{ item.Title }}
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Field, Uploader } from "vant";
export default {
  components: {
    [Icon.name]: Icon,
  },
  data() {
    return {
      id: null,
      viewData: {},
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$axios
        .get("/api/CloudClassroom/cloudClassroom-detail", {
          id: this.id,
        })
        .then((res) => {
          this.viewData = res.data;
        });
    },

    review(file) {
      if (file.uploadUrl) {
        window.open(file.uploadUrl);
      }
    },
    fileReview(file) {
      if (file.url) {
        const fileExtension = file.url.substring(file.url.lastIndexOf(".") + 1);

        if (
          fileExtension == "xls" ||
          fileExtension == "xlsx" ||
          fileExtension == "doc" ||
          fileExtension == "docx" ||
          fileExtension == "pptx" ||
          fileExtension == "ppt"
        ) {
          window.location.href =
            "https://view.officeapps.live.com/op/view.aspx?src=" + file.url;
        } else {
          this.$router.push({
            path: "Review",
            query: {
              url: file.url
            }
          });
        }
      }
    },
    viewResources(file) {
      if (file.Url) {
        window.open(file.Url);
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
